import { useEffect, useMemo, useState } from 'react';

import NextApp, { AppContext as NextAppContext } from 'next/app';

import { ApolloProvider } from '@apollo/client';
import Footer from '@components/Footer/Footer';
import { EnvironmentProvider } from '@env';
import { AppProps } from '@shared/next';
import { useApollo } from '@shared/next/apollo';
import { useAuthContext } from '@shared/next/auth';
import { getNextEnvironment } from '@shared/next/env';
import { Ethereum, Sepolia } from '@thirdweb-dev/chains';
import { ThirdwebProvider } from '@thirdweb-dev/react';

import '@shared/json-polyfills';
import '../styles/global.css';

const App = ({ Component, pageProps }: AppProps) => {
  const environment = useMemo(() => getNextEnvironment(), []);

  const [fontLoaded, setFontLoaded] = useState(false);

  useEffect(() => {
    const fontLink = document.createElement('link');
    fontLink.href = 'https://fonts.googleapis.com/css?family=Press+Start+2P';
    fontLink.rel = 'stylesheet';
    document.head.appendChild(fontLink);

    fontLink.onload = () => {
      setFontLoaded(true);
    };
  }, []);

  const { authToken } = useAuthContext();

  const apolloClientAuthorizationContextProvider = () => () => {
    return {
      headers: {
        Authorization: `Bearer ${authToken}`,
      },
    };
  };

  const apolloClient = useApollo(pageProps, {
    contextProviders: [apolloClientAuthorizationContextProvider()],
  });

  /*
  const router = useRouter();
  useEffect(() => {
    router.push('/maintenance');
  }, [router]);
  */
  return (
    <EnvironmentProvider environment={environment}>
      <ApolloProvider client={apolloClient}>
        <ThirdwebProvider clientId={environment.thirdwebClientId} supportedChains={[Ethereum, Sepolia]}>
          <div id="container">
            <div id="main"></div>
            {fontLoaded ? (
              <>
                <Component {...pageProps} />

                <Footer></Footer>
              </>
            ) : (
              ''
            )}
          </div>
        </ThirdwebProvider>
      </ApolloProvider>
    </EnvironmentProvider>
  );
};

App.getInitialProps = async (appContext: NextAppContext) => {
  const appProps = await NextApp.getInitialProps(appContext);

  if (typeof window !== 'undefined') {
    return {
      ...appProps,
    };
  }

  return {
    ...appProps,
    pageProps: {
      ...appProps.pageProps,
    },
  };
};

export default App;
