import j from 'joi';

import { APP_RELEASE_STAGES, Environment } from '../env';

/**
 * Environment variables available to the Next.js app.
 */
export interface NextEnvironment {
  NODE_ENV: Environment['env'];
  NEXT_PUBLIC_RELEASE_STAGE: Environment['releaseStage'];
  NEXT_PUBLIC_GRAPHQL_SERVER_URL: Environment['graphqlServerUrl'];
  NEXT_PUBLIC_ALCHEMY_KEY: Environment['alchemyKey'];
  NEXT_PUBLIC_3WEB_CLIENTID: Environment['thirdwebClientId'];
  NEXT_PUBLIC_DEPLOYMENT: Environment['deployment'];
  NEXT_PUBLIC_CONTRACT: Environment['contract'];
}

const NextEnvironmentSchema = j
  .object<NextEnvironment>({
    NODE_ENV: j.string().default('development'),
    NEXT_PUBLIC_RELEASE_STAGE: j.allow(...APP_RELEASE_STAGES).default('development'),
    NEXT_PUBLIC_GRAPHQL_SERVER_URL: j.string().required(),
    NEXT_PUBLIC_ALCHEMY_KEY: j.string().required(),
    NEXT_PUBLIC_3WEB_CLIENTID: j.string().required(),
    NEXT_PUBLIC_DEPLOYMENT: j.string().required(),
    NEXT_PUBLIC_CONTRACT: j.string().required(),
  })
  .options({ stripUnknown: true });

/**
 * Gets the environment variables available to the Next.js app.
 */
export const getNextEnvironment = (): Environment => {
  const { value, error } = NextEnvironmentSchema.validate(
    {
      NODE_ENV: process.env.NODE_ENV,
      NEXT_PUBLIC_RELEASE_STAGE: process.env.NEXT_PUBLIC_RELEASE_STAGE,
      NEXT_PUBLIC_GRAPHQL_SERVER_URL: process.env.NEXT_PUBLIC_GRAPHQL_SERVER_URL,
      NEXT_PUBLIC_ALCHEMY_KEY: process.env.NEXT_PUBLIC_ALCHEMY_KEY,
      NEXT_PUBLIC_3WEB_CLIENTID: process.env.NEXT_PUBLIC_3WEB_CLIENTID,
      NEXT_PUBLIC_DEPLOYMENT: process.env.NEXT_PUBLIC_DEPLOYMENT,
      NEXT_PUBLIC_CONTRACT: process.env.NEXT_PUBLIC_CONTRACT,
    },
    { abortEarly: false },
  );

  if (!value || error) {
    throw new Error(`Invalid configuration. `);
  }

  return {
    env: value.NODE_ENV,
    releaseStage: value.NEXT_PUBLIC_RELEASE_STAGE,
    isDevelopment: value.NEXT_PUBLIC_RELEASE_STAGE !== 'production' && value.NEXT_PUBLIC_RELEASE_STAGE !== 'staging',
    graphqlServerUrl: value.NEXT_PUBLIC_GRAPHQL_SERVER_URL,
    deployment: value.NEXT_PUBLIC_DEPLOYMENT,
    alchemyKey: value.NEXT_PUBLIC_ALCHEMY_KEY,
    thirdwebClientId: value.NEXT_PUBLIC_3WEB_CLIENTID,
    contract: value.NEXT_PUBLIC_CONTRACT,
  };
};
